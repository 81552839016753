@use 'src/media' as media;
@use 'src/variables' as variables;
@import '../../style/helper/media';

.sidebar {
  width: 100%;
  height: fit-content;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px !important;
  background: variables.$bgWhite;
  border-radius: 20px;
  box-shadow: 0 4px 12px 0 #00000010;
  @include mediaMaxWidthTablet {
    border-radius: 12px;
    padding: 8px 12px;
  }
  li {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    text-decoration: none;
    list-style-type: none;
    @include variables.fs20;
    color: variables.$fontBlack;
    white-space: nowrap;

    a {
      color: variables.$fontBlack;
      text-decoration: none;
      list-style-type: none;
      font-size: inherit;
      line-height: inherit;
      transition: all 0.4s ease-in-out;
      &.active,
      &:hover {
        color: #008500 !important;
      }
      &.active {
        font-weight: 600;
        border-radius: 20px;
      }
    }
    @include media.tablet {
      @include variables.fs16;
    }
    @include media.phone {
      @include variables.fs14;
    }

    &.order {
      @include mediaMaxWidthTablet {
        display: none;
      }
    }
  }

  @include mediaMaxWidthTablet {
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 20px;
    gap: 0px;
  }

  @include media.phone {
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 12px;
    gap: 0px;
  }
}
.sidebar:nth-of-type(3) {
  justify-content: space-evenly;
}

.order {
  margin-left: 10px;
}
.orderButton {
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rotate180 {
  transform: rotate(180deg);
}
div.orderBlock {
  display: none;
  width: fit-content;
  @include mediaMaxWidthTablet {
    display: flex;
    position: absolute;
    top: calc(100% + 12px);
    left: 0;

    .sidebar {
      padding: 8px;
      display: flex;
      flex-direction: column;
    }
  }
  @include media.phoneV {
    left: auto;
    right: 0;
  }
  .sidebar {
    gap: 20px;
    font-weight: 400;
  }
}

.phoneVertHidden {
  @include media.phoneV {
    display: none;
  }
}
.phoneVertOnly {
  display: none;

  @include media.phoneV {
    display: flex;
  }
}
.desktopOnly {
  @include mediaMaxWidthTablet {
    display: none;
  }
}
.desktopHidden {
  display: none;
  @include mediaMaxWidthTablet {
    display: block;
  }
}
.fitLi {
  width: fit-content;
}
.notification1Figure {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translateX(2px) translateY(-8px);
  display: grid;
  place-items: center;

  background-color: variables.$blockBorderColor;
  color: variables.$bgWhite;
  border-radius: 10px;
  @include variables.fs14;

  @include media.phoneV {
    width: 16px;
    height: 16px;
    transform: translateX(1px) translateY(-6px);
    @include variables.fs12;
  }
}
.notificationMoreThan1Figure {
  width: fit-content;
  padding: 0 4px;
}
