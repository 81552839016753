@import '../helper/media';
@import '../helper/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&display=swap');

.p2p,
.account,
.p2p__modal {
  color: $colorPrimary400;
  display: flex;
  flex-direction: column;
  .p2p__main {
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
  }
  .wrapper {
    padding: 0.75rem;
    border-radius: 1.25rem;
    background-color: $colorWhite;
    @include box-shadow;
    @include mediaMinWidthMobile {
      padding: 1.5rem;
    }
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
  .group {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    &.group_secondary {
      &:not(:last-child) {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $colorPrimary200;
      }
    }
    .group__header {
      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
      &.group__header_ico {
        display: flex;
        align-items: center;
        .group__ico {
          order: 2;
          margin-left: auto;
          @include mediaMinWidthTablet {
            display: none;
          }
        }
      }
    }
    .group__main {
      border-radius: 1.5rem;
      padding: 1rem;
      background-color: $colorPrimary100;
      @include mediaMinWidthTablet {
        padding: 1.5rem;
      }
      &.group__main_secondary {
        background-color: $colorSecondary200;
      }
      &.group__main_transparent {
        background-color: transparent;
        padding: 0;
      }
    }
  }

  .p2p__reviews {
    .reviews__header {
      font-weight: 500;
      text-align: center;
      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }
    }
    .reviews__preview {
      .preview__list {
        display: flex;
        justify-content: center;
      }

      .preview__item {
        padding: 0 0.75rem;
        cursor: pointer;
      }

      .form-item {
        pointer-events: none;
      }
    }

    .reviews__result {
      display: flex;
      justify-content: space-between;

      .result__main {
        display: flex;
        flex-direction: column;
      }

      .result__visual {
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }

      .result__date {
        color: $colorPrimary300;
        font-size: 0.875rem;
        line-height: 1.25rem;

        .date__item {
          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }

      .result__action {
        display: flex;
        align-items: flex-end;

        .action__list {
          display: flex;
        }

        .action__item {
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          align-items: flex-start;
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 1.5rem;
          }

          img,
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .p2p__filter {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    &.p2p__filter_active {
      .filter__main {
        display: block;
      }
    }
    .filter__formular {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .filter__main {
      width: 100%;
      display: none;
      @include mediaMinWidthMobile {
        display: block;
      }
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
    .filter__action {
      margin-left: auto;
    }
    .action__list {
      display: flex;
    }
    .action__item {
      padding: 0.25rem 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
      @include mediaMinWidthTablet {
        padding-top: 1.75rem;
      }
      &.action__item_init {
        .action__title {
          display: none;
          @include mediaMinWidthMobile {
            display: block;
          }
        }
        .action__ico {
          display: flex;
          align-items: flex-start;
          @include mediaMinWidthMobile {
            display: none;
          }
        }
      }
      &.action__item_reset {
        .action__title {
          display: none;
          @include mediaMinWidthTablet {
            display: block;
          }
        }
        .action__ico {
          display: flex;
          @include mediaMinWidthTablet {
            display: none;
          }
        }
      }
      .action__title {
        .title__text {
          color: $colorQuartyty500;
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 500;
          white-space: nowrap;
        }
      }
      .action__ico {
        width: 2rem;
        height: 2rem;
        align-items: flex-start;
        @include mediaMinWidthMobile {
          width: 1.5rem;
          height: 1.5rem;
        }
        img,
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .p2p__table {
    background-color: $colorWhite;
    border-radius: 1rem;
    overflow: hidden;
    .table__head {
      border-radius: 2rem;
      padding: 0 1.25rem;
      background-color: $colorPrimary400;
      display: none;
      @include mediaMinWidthMobile {
        display: block;
      }
      .table__list {
        display: flex;
      }
      .table__item {
        color: $colorWhite;
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 400;
        padding: 0.5rem;
        text-align: center;
      }
    }
    .table__layout {
      background-color: $colorWhite;
      padding: 0 0.75rem;
      @include mediaMinWidthMobile {
        @include mediaMinWidthMobile {
          padding: 0 1.25rem 1.25rem;
        }
      }
      .table__list {
        position: relative;
        &:not(:last-child) {
          border-bottom: 1px solid $colorPrimary200;
        }
      }
    }
    .table__preview {
      padding: 0.75rem 0;
      display: flex;
      flex-direction: column;
      @include mediaMinWidthMobile {
        flex-direction: row;
      }
      .table__header {
        width: 100%;
        display: flex;
        align-items: flex-start;
        .header__avatar {
          color: $colorWhite;
          background-color: $colorQuartyty500;
          border-radius: 50%;
          width: 2.5rem;
          min-width: 2.5rem;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
        .header__subtitle {
          display: flex;
          flex-direction: column;
          .subtitle__text {
            margin: 0;
          }
        }
      }
    }
    .table__dropdown {
      padding: 0 1.25rem;
      width: 100%;
      height: 100%;
      background: $colorWhite;
      z-index: 2;
      position: relative;
      max-height: 0;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      @include transition;
    }
    .table__item {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include mediaMinWidthMobile {
        width: 15rem;
        justify-content: center;
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
        @include mediaMinWidthMobile {
          margin-bottom: 0;
        }
      }
      &:last-child {
        justify-content: center;
      }
    }
    .home__item {
      @media (max-width: 768px) {
        display: block;
      }
    }
    .tags {
      .tags__list {
        justify-content: flex-start;
        @include mediaMinWidthMobile {
          justify-content: center;
        }
      }
    }
  }

  .ml-auto {
    margin-left: auto !important;
  }
  .ml-m-auto {
    @include mediaMinWidthMobile {
      margin-left: auto !important;
    }
  }
  .mb-m-auto {
    @include mediaMinWidthMobile {
      margin-bottom: auto !important;
    }
  }
  .m-m-0 {
    @include mediaMinWidthMobile {
      margin-bottom: 0 !important;
    }
  }
  .nowrap {
    flex-wrap: nowrap !important;
  }
  .w-m-100 {
    @include mediaMaxWidthMobile {
      width: 100% !important;
    }
  }
  .w-m-auto {
    @include mediaMaxWidthMobile {
      width: auto !important;
    }
  }
  .d-none {
    display: none !important;
  }
  .d-m-none {
    @include mediaMaxWidthMobile {
      display: none !important;
    }
  }
  .d-m-flex {
    @include mediaMaxWidthMobile {
      display: flex !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-weight: 500;

    &:not(:last-child) {
      margin: 0 0 1.25rem;
    }
  }

  h1,
  .h1 {
    font-size: 2rem;
    line-height: 2rem;
  }

  h2,
  .h2 {
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
  }

  h3,
  .h3 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
  }

  h4,
  .h4 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
  }

  h5,
  .h5 {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
  }

  h6,
  .h6 {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
  }

  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: $colorPrimary400;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-weight: 500;
  }

  a {
    color: $colorPrimary400;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  ul,
  p,
  ol {
    padding: 0;
    margin-bottom: 0;
  }

  ul,
  ol {
    list-style: none;

    ul,
    ol {
      margin: 0.5rem 0;
    }

    li {
      margin: 0;
    }
  }

  img,
  svg {
    outline: none;
    max-width: 100%;
    object-fit: cover;
    height: auto;
  }

  // BASE
  // container
  .container {
    width: 75rem;
    max-width: calc(100vw - 1rem);
    margin: 0 auto;
  }

  // row
  .row {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 1.25rem);
    margin: 0 -0.625rem;

    &.row_m-revers {
      @include mediaMaxWidthMobile {
        flex-direction: column-reverse;
      }
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    padding: 0 0.625rem;
    box-sizing: border-box;

    &.col_1 {
      width: 8.3333%;
    }

    &.col_2 {
      width: 16.666%;
    }

    &.col_3 {
      width: 25%;
    }

    &.col_4 {
      width: 33.3%;
    }

    &.col_5 {
      width: 41.666667%;
    }

    &.col_6 {
      width: 50%;
    }

    &.col_7 {
      width: 58.333333%;
    }

    &.col_8 {
      width: 66.666667%;
    }

    &.col_9 {
      width: 75%;
    }

    &.col_10 {
      width: 83.333333%;
    }

    &.col_11 {
      width: 91.666667%;
    }

    &.col_12 {
      width: 100%;
    }

    @include mediaMaxWidthDesktop {
      &.col_desktop-2 {
        width: 16.66%;
      }
      &.col_desktop-3 {
        width: 25%;
      }
      &.col_desktop-4 {
        width: 33.3%;
      }
      &.col_desktop-5 {
        width: 41.666667%;
      }
      &.col_desktop-6 {
        width: 50%;
      }
      &.col_desktop-7 {
        width: 58.333333%;
      }
      &.col_desktop-8 {
        width: 66.666667%;
      }
      &.col_desktop-9 {
        width: 75%;
      }
      &.col_desktop-10 {
        width: 83.333333%;
      }
      &.col_desktop-11 {
        width: 91.666667%;
      }
      &.col_desktop-12 {
        width: 100%;
      }
    }
    @include mediaMaxWidthTablet {
      &.col_tab-2 {
        width: 16.66%;
      }
      &.col_tab-3 {
        width: 25%;
      }
      &.col_tab-4 {
        width: 33.3%;
      }
      &.col_tab-5 {
        width: 41.666667%;
      }
      &.col_tab-6 {
        width: 50%;
      }
      &.col_tab-7 {
        width: 58.333333%;
      }
      &.col_tab-8 {
        width: 66.666667%;
      }
      &.col_tab-9 {
        width: 75%;
      }
      &.col_tab-10 {
        width: 83.333333%;
      }
      &.col_tab-11 {
        width: 91.666667%;
      }
      &.col_tab-12 {
        width: 100%;
      }
    }
    @include mediaMaxWidthMobile {
      &.col_mob-2 {
        width: 16.66%;
      }
      &.col_mob-3 {
        width: 25%;
      }
      &.col_mob-4 {
        width: 33.3%;
      }
      &.col_mob-5 {
        width: 41.666667%;
      }
      &.col_mob-6 {
        width: 50%;
      }
      &.col_mob-7 {
        width: 58.333333%;
      }
      &.col_mob-8 {
        width: 66.666667%;
      }
      &.col_mob-9 {
        width: 75%;
      }
      &.col_mob-10 {
        width: 83.333333%;
      }
      &.col_mob-11 {
        width: 91.666667%;
      }
      &.col_mob-12 {
        width: 100%;
      }
    }
    @include mediaMaxWidthMobilePort {
      &.col_mobp-2 {
        width: 16.66%;
      }
      &.col_mobp-3 {
        width: 25%;
      }
      &.col_mobp-4 {
        width: 33.3%;
      }
      &.col_mobp-5 {
        width: 41.666667%;
      }
      &.col_mobp-6 {
        width: 50%;
      }
      &.col_mobp-7 {
        width: 58.333333%;
      }
      &.col_mobp-8 {
        width: 66.666667%;
      }
      &.col_mobp-9 {
        width: 75%;
      }
      &.col_mobp-10 {
        width: 83.333333%;
      }
      &.col_mobp-11 {
        width: 91.666667%;
      }
      &.col_mobp-12 {
        width: 100%;
      }
    }
  }

  .d-flex {
    display: flex !important;
  }

  .justify-end {
    justify-content: flex-end !important;
  }
  .justify-center {
    justify-content: center;
  }

  .justify-space-between {
    justify-content: space-between;
  }

  .d-flex {
    display: flex;
  }
  .direction-column {
    flex-direction: column;
  }
  .align-center {
    align-items: center;
  }
  .align-end {
    align-items: flex-end;
  }

  @include mediaMaxWidthMobile {
    .justify-m-center {
      justify-content: center;
    }
    .justify-m-end {
      justify-content: flex-end;
    }
    .justify-m-start {
      justify-content: flex-start;
    }
  }
  @include mediaMinWidthMobile {
    .m-no-wrap {
      flex-wrap: nowrap !important;
    }
  }

  .w-100 {
    width: 100% !important;
  }
  .invalid-input {
    border-bottom: 2px solid red !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .pb-0 {
    padding-bottom: 0 !important;
  }
  .b-0 {
    border: 0 !important;
  }

  .m-0 {
    margin: 0 !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .text-center {
    text-align: center !important;
  }

  .colorSecondary300 {
    color: $colorSecondary300 !important;
  }

  .colorSecondary400 {
    color: $colorSecondary400 !important;
  }

  .colorSecondary500 {
    color: $colorSecondary500 !important;
  }

  .colorTertiary300 {
    color: $colorTertiary300 !important;
  }

  .colorTertiary400 {
    color: $colorTertiary400 !important;
  }

  .colorTertiary500 {
    color: $colorTertiary500 !important;
  }
  .colorTertiary600 {
    color: $colorTertiary600 !important;
  }

  .colorPrimary200 {
    color: $colorPrimary200 !important;
  }

  .colorPrimary300 {
    color: $colorPrimary300 !important;
  }

  .colorPrimary400 {
    color: $colorPrimary400 !important;
  }
  .colorPrimary600 {
    color: $colorPrimary600 !important;
  }
  .colorPrimary700 {
    color: $colorPrimary700 !important;
  }

  .colorQuartyty200 {
    color: $colorQuartyty200 !important;
  }

  .colorQuartyty300 {
    color: $colorQuartyty300 !important;
  }

  .colorQuartyty400 {
    color: $colorQuartyty400 !important;
  }

  .colorQuartyty500 {
    color: $colorQuartyty500 !important;
  }

  .bgcolorTertiary300 {
    background-color: #ffebf1 !important;
    border-radius: 2rem;
    color: #000;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.25rem 0.5rem;
  }

  // Стилизация скролла
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    background: $colorWhite;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $colorSecondary400;

    &:hover {
      background-color: $colorSecondary500;
    }
  }

  .p2p__tooltip {
    color: $colorPrimary400;
    background-color: $colorTertiary300;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
    padding: 1.5rem;
    border-radius: 1.25rem;
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    .p2p__tooltip {
      padding: 0.75rem;
    }
  }
  .tooltip__order {
    display: flex;
    flex-direction: column;
    @include mediaMinWidthTablet {
      flex-direction: row;
      justify-content: space-between;
    }
    &:not(:last-child) {
      margin-bottom: 0.75rem;
      padding-bottom: 0.75rem;
      border-bottom: 1px solid $colorPrimary600;
    }
    .order__header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      @include mediaMinWidthTablet {
        margin-bottom: 0;
        margin-right: 1rem;
        width: 50%;
      }
    }
    .order__main {
      @include mediaMinWidthTablet {
        width: 40%;
      }
    }
  }
  .fontMiddle {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  .fontTiny {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  // dataText
  .datatext {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    .datatext__list {
      margin: 0 -1rem -1rem;
      width: calc(100% + 2rem);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .datatext__item {
      padding: 0 1rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
    }
    .datatext__title {
      &:not(:last-child) {
        margin-right: 0.25rem;
      }
    }
    .datatext__media {
      width: 1rem;
      height: 1rem;
      display: flex;
      align-items: flex-start;
      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .empty_space {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    font-size: 16px;
  }
}
.flex-nowrap {
  flex-wrap: nowrap;
}

.comment_label {
  margin-bottom: 10px;
}
.comment_text {
  margin-bottom: 10px;
  margin-left: 5px;
}
.comment_footer {
  display: flex;
  justify-content: flex-end;
}
.comment_btn_list {
  display: flex;
  gap: 10px;
}
.comment_date {
  font-size: 0.9em;
  color: #888;
}
.comment_btn {
  padding: 5px;
  margin-top: 5px;
}
