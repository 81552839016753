@import '../helper/media';
@import '../helper/variables';
// TABLE
.layout,
.account,
.p2p {
  .layout__table {
    border-radius: 1.25rem;
    background-color: $colorWhite;
    &.layout__table_center {
      @include mediaMinWidthTablet {
        .table__item {
          justify-content: center;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    .table__head {
      color: $colorWhite;
      border-radius: 1.25rem;
      background-color: $colorPrimary400;
      display: none;
      @include mediaMinWidthTablet {
        display: flex;
      }
      .table__item {
        padding: 0.75rem 1.25rem;
        font-size: 1.25rem;
        line-height: 1.25rem;
        font-weight: 500;
      }
    }
    .table__body {
      .table__list {
        &:not(:last-child) {
          position: relative;
          &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 1rem;
            height: 1px;
            width: calc(100% - 2rem);
            background-color: $colorPrimary200;
          }
        }
      }
      .table__item {
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 500;
        padding: 0.75rem 1.25rem;
        @include mediaMinWidthTablet {
          padding: 1.25rem 1.25rem;
        }
      }
    }
    .table__list {
      padding: 1rem 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      @include mediaMinWidthTablet {
        padding: 0;
        flex-direction: row;
      }
    }
    .table__item {
      display: flex;
      align-items: center;
      @include mediaMaxWidthTablet {
        width: 100% !important;
        justify-content: space-between;
      }
    }
    .table__header {
      display: block;
      @include mediaMinWidthTablet {
        display: none;
      }
      .table__title {
        color: $colorPrimary300;
      }
    }
    .table__main {
      display: flex;
      align-items: center;
    }
    .table__ico {
      width: 1.5rem;
      height: 1.5rem;
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}
