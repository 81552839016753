@use 'src/media' as media;
@use 'src/variables' as variables;

.idWrapper {
  display: flex;
  margin-top: 20px;
  gap: 20px;

  align-items: flex-end;
}
.idTitle {
  font-weight: 500;
  @include variables.fs16;
  @include media.phone {
    @include variables.fs14;
  }
}
.idValue {
  margin-bottom: 0;
  @include variables.fs18;
  @include media.phone {
    @include variables.fs16;
  }

  background: linear-gradient(135deg, #8c00fd 0%, #a800c0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}