@use 'src/media' as media;
@use 'src/variables' as variables;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    @include media.tablet {
      font-size: 16px;
      line-height: 19px;
    }

    @include media.phone {
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.inputBorder {
  margin-top: 10px;
  position: relative;
}
.password {
  input {
    padding-right: 30px;
  }
}

.passwordFieldIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  &:hover {
    cursor: pointer;
  }


}
