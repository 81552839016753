$mediaMinWidthDesktop: 1240px;
$mediaMaxWidthDesktop: 2479px;
$mediaMinWidthTablet: 992px;
$mediaMaxWidthTablet: 991px;
$mediaMinWidthMobile: 768px;
$mediaMaxWidthMobile: 767px;
$mediaMinWidthMobilePort: 580px;
$mediaMaxWidthMobilePort: 579px;

/*mediaWidthDesktop -1201*/
@mixin mediaMinWidthDesktop {
  @media (min-width: #{$mediaMinWidthDesktop}) {
    @content;
  }
}
@mixin mediaMaxWidthDesktop {
  @media (max-width: #{$mediaMaxWidthDesktop}) {
    @content;
  }
}
/*WidthTabletLand-992*/
@mixin mediaMinWidthTablet {
  @media screen and (min-width: #{$mediaMinWidthTablet}) {
    @content;
  }
}
@mixin mediaMaxWidthTablet {
  @media screen and (max-width: #{$mediaMaxWidthTablet}) {
    @content;
  }
}
/*WidthTabletLand-768*/
@mixin mediaMinWidthMobile {
  @media screen and (min-width: #{$mediaMinWidthMobile}) {
    @content;
  }
}
@mixin mediaMaxWidthMobile {
  @media screen and (max-width: #{$mediaMaxWidthMobile}) {
    @content;
  }
}
/*WidthTabletLand-360*/
@mixin mediaMinWidthMobilePort {
  @media screen and (min-width: #{$mediaMinWidthMobilePort}) {
    @content;
  }
}
@mixin mediaMaxWidthMobilePort {
  @media screen and (max-width: #{$mediaMaxWidthMobilePort}) {
    @content;
  }
}
