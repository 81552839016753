@import '../../helper/media';
@import '../../helper/variables';
.layout__banner{
  padding: 2rem;
  border-radius: 1.25rem;
  background-color: $colorWhite;
  @include box-shadow;
  .banner__layout{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @include mediaMinWidthMobile{
      height: 100%;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
    }
  }
  .banner__header,
  .banner__main,
  .banner__footer{
    width: 100%;
    &:not(:last-child){
      margin-bottom: 1.5rem;
      @include mediaMinWidthTablet{
        margin-bottom: 2rem
      }
    }
  }
  .banner__footer{
    margin-top: auto;
  }
  .banner__media{
    margin-bottom: 2rem;
    @include mediaMinWidthTablet{
      margin-bottom: 0;
    }
  }
}