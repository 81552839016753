@import '../../helper/media';
@import '../../helper/variables';
// WYSIWYG
.p2p,
.account {
  .wysiwyg {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
    ul,
    p,
    ol {
      padding: 0;
      margin-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }

      &:empty {
        display: none;
      }

      li {
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }
    ol {
      counter-reset: my-awesome-counter;
      list-style: none;
      li {
        counter-increment: my-awesome-counter;
        padding-left: 2rem;
        position: relative;
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
        &:before {
          color: $colorWhite;
          background-color: $colorPrimary400;
          font-size: 0.875rem;
          line-height: 1rem;
          content: counter(my-awesome-counter);
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -0.25rem;
          left: 0;
        }
      }
    }
    ol {
      counter-reset: my-awesome-counter;
      list-style: none;
      li {
        counter-increment: my-awesome-counter;
        padding-left: 2rem;
        position: relative;
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
        &:before {
          color: $colorWhite;
          background-color: $colorPrimary400;
          font-size: 0.875rem;
          line-height: 1rem;
          content: counter(my-awesome-counter);
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -0.125rem;
          left: 0;
        }
        strong {
          &:not(:last-child) {
            margin-bottom: 0.75rem;
            display: block;
          }
        }
      }
    }
    .link {
      color: $colorPrimary300;
      font-size: 0.75rem;
      line-height: 0.75rem;
      position: relative;
      text-decoration: none;
      cursor: pointer;
      @include transition;
      &:after {
        content: '';
        background-color: $colorPrimary300;
        position: absolute;
        width: 100%;
        height: 1px;
        top: 100%;
        left: 0;
        @include transition;
      }
      &:hover {
        color: $colorQuartyty500;
        &:after {
          background-color: $colorQuartyty500;
        }
      }
    }
    small {
      font-size: 0.875rem;
      line-height: 1rem;
      display: inline-block;
    }
  }
}
