@use 'src/media' as media;
@use 'src/variables' as variables;

.block {
  position: relative;
  padding: 20px 0;
  // border-bottom: 1px solid;
  // border-image: var(--border-color) 1;
}

.body {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @include media.phone {
    flex-direction: column;
  }
}
.title {
  font-weight: 500;
  @include variables.fs32;
  margin-bottom: 20px;

  @include media.phone {
    @include variables.fs20;
  }
}

.selectTitle {
  @include variables.fs18;
  margin-bottom: 10px;

  @include media.phone {
    @include variables.fs16;
  }
}

.buttonBody {
  display: flex;
  justify-content: flex-start;
  gap: 5px;

  margin-bottom: 20px;
}

.button {
  border-radius: 10px;
  width: 134px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  @include variables.fs14;
  background-color: white;

  @include media.phone {
    width: 100px;
  }
}

.buttonGreen {
  border: 1px solid #666666;
}

.buttonYellow {
  border: 1px solid #f9d519;
}

.buttonRed {
  border: 1px solid var(--danger-color);
}

.buttonGreenActive {
  border: 1px solid #666666;
  background: radial-gradient(107.25% 302.83% at 3.5% 11.32%, #ccfff3 0%, #d0f4f5 93.26%);
}

.buttonYellowActive {
  border: 1px solid #f9d519;
  background: #fff7ca;
}

.buttonRedActive {
  border: 1px solid #ff004d;
  background: #ffd2e0;
}

.list {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.item {
  opacity: 0.7;
  @include variables.fs14;

  @include media.phone {
    @include variables.fs12;
  }
}

.danger {
  color: var(--danger-color);
  font-weight: 400;
  @include variables.fs14;
  margin-bottom: 10px;

  @include media.phone {
    @include variables.fs12;
  }
}

.dangerBody {
  margin-bottom: 20px;
  @include media.phone {
    margin-bottom: 0px;
  }
}

.qrCodeBody {
  display: flex;
  height: max-content;
  aspect-ratio: 1;

  @include media.phone {
    width: 100%;
    height: 155px;
    justify-content: center;
  }
}

.qrCode {
  width: 100%;
  height: 100%;

  @include media.phone {
    width: 155px;
    height: 155px;
  }
}
.address {
  font-weight: 400;
  @include variables.fs18;
  padding-left: 1px;
  margin-bottom: 10px;

  @include media.phone {
    @include variables.fs14;
  }
}

.inputBody {
  position: relative;
  width: 613px;

  @include media.phone {
    width: 100%;
  }
}

.inputImageBlock {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;

  border: 0;

  @include media.phone {
    width: 35px;
  }
  @include media.phoneV {
    height: 30px;
  }
}

.inputImage {
  width: 17px;
  height: 20px;

  @include media.phone {
    width: 15px;
    height: 17px;
  }
}

.buttonAddress {
  width: 240px;
  height: 40px;
  @include variables.fs16;
}
