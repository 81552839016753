@import '../../helper/media';
@import '../../helper/variables';
// Section
.layout {
  font-size: 0.875rem;
  line-height: 1.25rem;
  @include mediaMinWidthTablet {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  .layout__breadcrumb {
    position: relative;
    z-index: 2;
    @include mediaMinWidthTablet {
      margin: -3rem 0;
    }
    .breadcrumb__list {
      display: flex;
      overflow: hidden;
      overflow-x: auto;
    }
    .breadcrumb__item {
      &:not(:last-child) {
        margin-right: 1.25rem;
        &:before {
          content: '';
          left: calc(100% + 0.625rem);
          top: 50%;
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 50%;
          background-color: $colorPrimary300;
          position: absolute;
          @include transform;
        }
        &:hover {
          .breadcrumb__text {
            color: $colorQuartyty500;
          }
        }
        .breadcrumb__text {
          cursor: pointer;
        }
      }
    }
    .breadcrumb__text {
      color: $colorPrimary300;
      font-size: 0.75rem;
      line-height: 1.5rem;
      white-space: nowrap;
      @include transition;
    }
  }

  .layout__main {
    padding: 2rem 0;
    background-color: $colorPrimary100;
    overflow: hidden;
    @include mediaMinWidthTablet {
      padding: 4.5rem 0;
    }
  }
  .layout__section {
    padding: 2rem 0;
    @include mediaMinWidthTablet {
      padding: 4rem 0;
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    .section__header,
    .section__main,
    .section__footer {
      &:not(:last-child) {
        margin-bottom: 2rem;
        @include mediaMinWidthTablet {
          margin-bottom: 4rem;
        }
      }
    }
    .section__content {
      margin-bottom: 2rem;
      @include mediaMinWidthTablet {
        margin-bottom: 0;
      }
    }
  }

  .section__navigation {
    display: flex;
    justify-content: center;
    .navigation__arrow {
      width: 2.75rem;
      height: 2.75rem;
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      transform: translateX(0);
      @include transition;
      &.navigation__arrow_prev {
        &:hover {
          transform: translateX(-0.25rem);
        }
      }
      &.navigation__arrow_next {
        &:hover {
          transform: translateX(0.25rem);
        }
      }
    }
    .navigation__pagination {
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      .pagination__list {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .pagination__item {
        color: $colorPrimary300;
        font-size: 1rem;
        line-height: 1rem;
        font-weight: 500;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        @include transition;
        &:hover,
        &.pagination__item_active {
          color: $colorPrimary400;
        }
      }
    }
  }

  .section__filter {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    .filter__tags {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
    .tags__list {
      @include mediaMinWidthTablet {
        padding: 0 4rem;
      }
    }
    .tags__item {
      padding: 0 0.25rem;
      &:hover,
      .tags__item_active {
        .tags__text {
          color: $colorWhite;
          background-color: $colorPrimary400;
        }
      }
    }
    .tags__text {
      cursor: pointer;
      color: $colorPrimary400;
      background-color: $colorPrimary200;
      font-weight: 500;
      padding: 0.75rem;
      border-radius: 1.5rem;
      display: block;
      text-align: center;
      @include transition;
    }
  }
}
