@use 'src/media' as media;
@use 'src/variables' as variables;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Montserrat:wght@400;500;600&display=swap');

:root {
  --primary-color: radial-gradient(150.69% 118.33% at 123.17% 100%, #c6fff1 0%, #e7c8ff 100%);
  --title-color: radial-gradient(150.69% 118.33% at 123.17% 100%, #003629 0%, #7f00e2 100%);
  --danger-color: #ff004d;
  --border-color: radial-gradient(96.1% 93.99% at 0% 2.73%, #ccfff3 0%, #c985ff 100%);
  --active-gradient: radial-gradient(107.25% 302.83% at 3.5% 11.32%, #ccfff3 0%, #d0f4f5 93.26%);
  --active-color: radial-gradient(107.25% 302.83% at 3.5% 11.32%, #ccfff3 0%, #d0f4f5 93.26%);
  --active-menu-color: #7f00e2;
  --font-family: 'Inter';
  --size-h1: 32px;
  --size-h2: 26px;
  --size-h3: 20px;
  --size-h4: 18px;
  --size-h5: 16px;
  --size-h6: 14px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  //position: relative;
  letter-spacing: 0.01em;
}
body {
  background-color: #f8f9fd;
}

h1 {
  @include variables.fs32;
}
h2 {
  @include variables.fs20;
}
h3 {
  @include variables.fs18;
}
h4 {
  @include variables.fs16;
}
h5 {
  @include variables.fs14;
}
h6 {
  @include variables.fs12;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  border: 0;
  outline: none;
  cursor: pointer;

  background-color: transparent;
}
.titleWrapper {
  display: flex;
  justify-content: space-between;
}
.title {
  font-weight: 500;
  @include variables.fs32;
  margin-bottom: 20px;
  @include variables.titleColorAlt;

  @include media.phone {
    @include variables.fs20;
  }
}

.flow {
  width: 1200px;
  min-width: 360px;
  margin: 0 auto;
  padding: 2.5rem 0;

  @include media.mobileDevices {
    width: 100%;
    margin: 0;
  }

  @include media.tablet {
    padding: 0 18px;
  }

  @include media.phoneH {
    padding: 0 14px;
  }
  @include media.phoneV {
    padding: 2.5rem .75rem;
  }
}

.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 230px 1fr;
  justify-content: center;
  gap: 5px;

  @include media.mobileDevices {
    grid-template-columns: 1fr;
  }
}

.content {
  width: 965px;
  @include media.mobileDevices {
    width: 100%;
  }
}

.blockWrapper {
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 20px;
  background: variables.$bgWhite;


  @include media.phoneV {
    padding: 10px;
  }
}

:global(.zIndex10) {
  z-index: 10 !important;
}
