@import '../../helper/media';
@import '../../helper/variables';
.layout__news{
  .news__article{
    color: $colorPrimary400;
    @include mediaMinWidthTablet{
      padding: 1.5rem;
      background-color: $colorWhite;
      border-radius: 1.25rem;
    }
    &:not(:last-child){
      margin-bottom: 4rem;
      &:before{
        content: "";
        position: absolute;
        top: calc(100% + 2rem);
        left: 0;
        height: .125rem;
        width: 100%;
        background-color: $colorPrimary200;
      }
    }
    .article__header{
      &:not(:last-child){
        margin-bottom: 1.5rem;
      }
    }
    .article__media{
        margin-bottom: 1.5rem;
    }
    .article__title{
      margin-bottom: 1.5rem;
    }
    .article__date{
      display: flex;
      justify-content: flex-start;
      @include mediaMinWidthTablet{
        justify-content: flex-end;
      }
      .date__text{
        font-size: 1rem;
        line-height: 2.5rem;
        font-weight: 500;
      }
    }
  }
  .news__list{
    margin-bottom: -1.25rem;
    .news__item{
      margin-bottom: 1.25rem;
      background-color: $colorWhite;
      border-radius: 1.25rem;
      box-shadow: 0 0.25rem .75rem rgba(0, 0, 0, 0.03);
      @include transition;
      &:hover{
        box-shadow: 0 0.25rem .75rem rgba(0, 0, 0, 0.1);
      }
    }
    .news__link{
      padding: 1.5rem;
      color: $colorPrimary400;
      display: flex;
      flex-direction: column;
    }
    .news__media{
      margin: -1.5rem -1.5rem 1.5rem;
      width: calc(100% + 3rem);
      border-radius: 1.25rem;
      display: flex;
      align-items: flex-start;
      overflow: hidden;
      img,
      svg{
        width: 100%;
        height: 100%;
        transform: scale(1);
        @include transition;
      }
    }
    .news__layout{
      display: flex;
      flex-direction: column;
    }
    .news__layout,
    .news__main,
    .news__footer{
      &:not(:last-child){
        margin-bottom: 1.5rem;
        @include mediaMinWidthTablet{
          margin-bottom: 2rem;
        }
      }
    }
    .news__footer{
      margin-top: auto;
    }
    .news__date{
      .date__text{
        color: $colorPrimary300;
      }
    }
  }
}