@use 'src/media' as media;
@use 'src/variables' as variables;

.p2p__security {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;

  .block_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 15px;
    }
  }

  .text_wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (max-width: 768px) {
      align-items: center;
      text-align: center;
    }
  }

  .title {
    margin-bottom: 10px;
    font-size: 32px;
    font-weight: 400;
    color: #000000;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .subtitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .description {
    font-size: 14px;
    color: #888;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .status_wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;

    @media (max-width: 768px) {
      justify-content: center;
      margin-top: 10px;
    }
  }

  .status_enabled,
  .status_disabled {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 20px;

    @media (max-width: 768px) {
      margin-right: 10px;
    }
  }

  .status_enabled {
    color: $colorSecondary500;
  }

  .status_disabled {
    color: #888;
  }

  .button_wrapper {
    display: flex;
    gap: 10px;

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      gap: 5px;
    }
  }

  .status_loading {
    display: flex;
    align-items: center;
    color: $colorPrimary300;
    margin-right: 20px;

    @media (max-width: 768px) {
      margin-right: 10px;
    }
  }
}

.p2p__security_modal {
  text-align: center;
  .p2p__security_container {
    border-radius: 1.25rem;
    color: $colorPrimary400;
    background-color: #f6f6f6;
    font-size: 1rem;
    font-weight: 500;
    padding: 1rem 1rem 0.5rem 1rem;
    color: #000000;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      margin-bottom: 0.5rem;
      padding: 1rem;
    }
  }

  .twoFA__title {
    font-size: 20px;
    margin-bottom: 1rem;
    text-align: center;
  }

  .twoFA__step {
    font-size: 16px;

    @media (max-width: 768px) {
      line-height: 1;
    }
  }

  .twoFA__description {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
    margin: 1rem 1rem;
  }

  .twoFA__qrCode {
    display: block;
    margin: 1rem auto;
    width: 30%;
    height: 30%;

    @media (max-width: 768px) {
      margin: 10px auto;
      width: 60%;
      height: 60%;
    }
  }

  .twoFA__preShared {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #555;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .copy_icon svg {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    transition: stroke 0.3s ease;
    margin-left: 0.3rem;
  }

  .copy_icon.copied svg {
    stroke: variables.$colorSecondary500;
    width: 1.1rem;
    height: 1.1rem;
  }

  .error_message {
    color: variables.$fontRed;
    font-weight: bold;
    margin: 1rem 0;
  }

  .twoFA_posting__title {
    font-size: 20px;
    margin-bottom: 1rem;
    text-align: center;

    @media (max-width: 768px) {
      margin-bottom: 0.5rem;
      font-size: 16px;
      line-height: 1;
    }
  }

  .twoFA_posting__step {
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 1;
    }
  }

  .twoFA_posting__description {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
    margin: 0.5rem;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.p2p__security_email_modal {
  .p2p__security_container {
    border-radius: 1.25rem;
    color: $colorPrimary400;
    background-color: #f6f6f6;
    font-size: 1rem;
    font-weight: 500;
    padding: 1rem 1rem 0.5rem 1rem;
    color: #000000;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      margin-bottom: 0.5rem;
      padding: 1rem;
    }
  }

  .twoFA_email__code {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
  }
  .twoFA_email__code > div:first-child {
    width: 66.666%;
    flex-shrink: 0;
  }
  .twoFA_email__code button {
    white-space: nowrap;
  }

  .twoFA_email__title {
    font-size: 20px;
    margin-bottom: 1rem;
    text-align: center;
  }

  .twoFA_email__step {
    font-size: 16px;
  }

  .twoFA_email__btn {
    @media (max-width: 768px) {
      font-size: 0.75rem;
    }
  }
  .twoFA_email__btn_timer {
    @media (max-width: 768px) {
      font-size: 0.5rem;
    }
  }

  .twoFA_email__description {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
    margin: 0.5rem;
  }
  .twoFA_email__error_message {
    color: variables.$fontRed;
    font-weight: bold;
    margin: 1rem 0;
  }
}
