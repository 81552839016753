@import '../helper/media';
@import '../helper/variables';
// Modal
// modal-wrap
.p2p__modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: rgba(102, 102, 102, 0.25);
  backdrop-filter: blur(12.5px);
  display: flex;
  justify-content: center;
  @media screen and (min-width: #{$mediaMinWidthTablet}) and (min-height: 40rem) {
    align-items: center;
  }
}
.layout_modal-active {
  .p2p__modal {
    visibility: visible;
    opacity: 1;
  }
}
.modal__layout {
  cursor: auto;
  width: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 3rem 1.5rem 1.5rem;
  background-color: $colorWhite;
  border: 1px solid $colorPrimary200;
  z-index: 111;
  @media screen and (min-width: #{$mediaMinWidthTablet}) and (min-height: 40rem) {
    border-radius: 1rem;
    padding: 1.5rem;
    max-height: 95vh;
  }
  &.modal768 {
    max-width: 48rem;
  }
  &.modal640 {
    max-width: 40rem;
  }
  &.modal600 {
    max-width: 37.5rem;
  }
  &.modal480 {
    max-width: 30rem;
  }
  .modal__action {
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 3;
    transform: rotate(0deg);
    @include transition;
    @media screen and (min-width: #{$mediaMinWidthTablet}) and (min-height: 40rem) {
      top: 1.5rem;
      right: -2.5rem;
    }
    &:hover {
      transform: rotate(180deg);
    }
    img,
    svg {
      width: 100%;
      height: 100%;
      @include transition;
      path {
        stroke: $colorPrimary400;
        @include transition;
      }
    }
  }
  .modal__header {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
  .modal__main {
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 8rem);
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
  .modal__section {
    &:not(:last-child) {
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid $colorPrimary200;
    }
  }
}
// MODAL DATABASE
.modal__database {
  font-weight: 400;
  .database__list {
    margin-bottom: -0.75rem;
    display: flex;
    flex-direction: column;
  }
  .database__item {
    margin-bottom: 0.75rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .database__key {
    margin-right: 1rem;
    .database__title {
      .title__text {
        color: $colorPrimary300;
        font-size: 0.875rem;
        line-height: 1rem;
      }
    }
  }
  .database__value {
    display: flex;
    flex-direction: column;
    .value__title {
      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }
      .title__text {
        color: $colorPrimary400;
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 500;
        text-align: right;
        display: block;
      }
    }
    .database__data {
      .data__list {
        width: calc(100% + 1.5rem);
        margin: 0 -1.5rem -0.5rem 0;
        display: flex;
        flex-wrap: wrap;
      }
      .data__item {
        color: $colorSecondary500;
        margin: 0 1.5rem 0.5rem 0;
        display: flex;
        align-items: center;
        &.data__item_secondary {
          color: $colorTertiary500;
          .data__media {
            img,
            svg {
              fill: $colorTertiary500;
            }
          }
        }
      }
      .data__title {
        &:not(:last-child) {
          margin-right: 0.25rem;
        }
      }
      .data__media {
        width: 0.75rem;
        height: 0.75rem;
        display: flex;
        align-items: flex-start;
        transform: translateY(-0.125rem);
        &:not(:last-child) {
          margin-right: 0.25rem;
        }
        img,
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
// MODAL METHODS
.modal__methods {
  .methods__list {
    display: flex;
    flex-direction: column;
  }
  .methods__item {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
  .methods__label {
    padding: 1.5rem;
    background-color: $colorPrimary100;
    border-radius: 1.25rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
  }
  .methods__header {
    padding: 0.75rem;
    border-radius: 1.5rem;
    background-color: $colorWhite;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
  .checkbox__input {
    &:checked {
      & ~ .checkbox__main {
        &:before {
          border-color: $colorQuartyty500;
        }
      }
    }
  }
  .checkbox__main {
    position: unset !important;
    &:before {
      content: '';
      position: absolute;
      border: 1px solid $colorPrimary100;
      border-radius: 1.25rem;
      cursor: pointer;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include transition;
    }
  }
  .modal__margin_bottom {
    margin-bottom: 15px !important;
  }
}

// modal__chat
.modal__chat {
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    &:not(:last-child) {
      margin-right: 0.25rem;
    }
  }
  .chat__text {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 500;
  }
}

// MODAL NOTIFICATIONS
.modal__notifications {
  .notifications__groups {
    display: flex;
    flex-direction: column;
  }
  .notifications__group {
    color: $colorPrimary300;
    font-size: 0.875rem;
    line-height: 1.25rem;
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
  .group__header {
    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $colorPrimary400;
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
  .group__main {
    padding: 1.5rem;
    border-radius: 1.25rem;
    background-color: $colorPrimary100;
  }
  .notifications__list {
    display: flex;
    flex-direction: column;
  }
  .notifications__item {
    &:not(:last-child) {
      margin-bottom: 0.75rem;
      padding-bottom: 0.75rem;
      border-bottom: 1px solid $colorPrimary600;
    }
  }
  .notifications__label {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $colorPrimary400;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
  .notifications__switcher {
    .switcher__input {
      position: absolute;
      left: -999999px;
      &:checked ~ .switcher__text {
        &:before {
          background-color: $colorQuartyty500;
        }
        &:after {
          right: 0.25rem;
        }
      }
    }
    .switcher__text {
      width: 2.5rem;
      position: relative;
      display: block;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        @include transition;
      }
      &:before {
        width: 2.5rem;
        height: 1.5rem;
        border-radius: 2rem;
        background-color: $colorPrimary500;
        box-shadow: none;
      }
      &:after {
        width: 1rem;
        height: 1rem;
        background-color: $colorWhite;
        border-radius: 50%;
        top: 0.25rem;
        right: 1.25rem;
        z-index: 2;
        opacity: 1;
      }
    }
  }
}
.modal__trading {
  .trading__information {
    border-radius: 1.25rem;
    padding: 0.75rem;
    margin-bottom: 2.5rem;
    background-color: $colorPrimary100;
    @include mediaMinWidthTablet {
      margin-bottom: 0;
    }
  }
  .information__about {
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
    border-bottom: 1px solid $colorPrimary600;
  }
  .about__header {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
    .information__avatar {
      color: $colorWhite;
      background-color: $colorQuartyty500;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    .information__title {
      color: $colorQuartyty500;
    }
  }
  .about__protection {
    .protection__list {
      margin-bottom: -0.5rem;
      display: flex;
      flex-wrap: wrap;
    }
    .protection__item {
      color: $colorSecondary500;
      font-size: 0.875rem;
      line-height: 1rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
      img,
      svg {
        width: 0.75rem;
        height: 0.75rem;
        flex-shrink: 0;
        transform: translateY(-0.125rem);
        &:not(:last-child) {
          margin-right: 0.25rem;
        }
      }
    }
  }
  .trading__formular {
    display: flex;
    width: 100%;
    height: 100%;
    .formular {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .formular__footer {
      margin-top: auto;
    }
  }
}
