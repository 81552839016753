@import '../helper/media';
@import '../helper/variables';
// tags
.p2p,
.account,
.layout {
  .tags {
    &.tags_secondary {
      .tags__text {
        font-size: 0.75rem;
        line-height: 0.75rem;
        padding: 0.25rem;
      }
    }
    .tags__list {
      margin: 0 -0.25rem -0.25rem;
      display: flex;
      flex-wrap: wrap;
    }

    .tags__item {
      margin: 0 0.25rem 0.25rem;
      display: flex;
    }

    .tags__text {
      color: $colorPrimary400;
      font-size: 1rem;
      line-height: 2rem;
      font-weight: 500;
      padding: 0.25rem 0.75rem;
      border-radius: 2rem;
      display: block;
      white-space: nowrap;
    }
  }
}
