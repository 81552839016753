@use '../../../media' as media;
@use '../../../variables' as variables;

.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 78px;
  padding: 15px;
}

.link{
  color:var(--active-menu-color);
}