@import '../../helper/media';
@import '../../helper/variables';

.layout__offices {
  .offices__map {

    .map__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
    }

    .map__preview {
      width: 100%;
      padding: .625rem;
      border-radius: 2rem;
      background-color: rgba(32, 37, 58, .1);
      @include mediaMinWidthTablet{
        padding: 1.25rem;
      }
      &:not(:last-child){
        margin-bottom: 1.25rem;
      }
    }

    .map__media {
      padding-top: 45%;
      border-radius: 2rem;
      box-shadow: 0 4px 30px 0 #00000040;
      display: flex;
      align-items: flex-start;
      overflow: hidden;
      position: relative;
      @include mediaMinWidthTablet{
        padding-top: 110%;
      }
      img,
      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .map__action {
    width: 17.5rem;
    display: flex;
    justify-content: center;

    .btn {
      width: 100%;
    }
  }


  .offices__layout {
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;

    .offices__header {
      display: flex;
      justify-content: flex-start;

      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }
    }

    .offices__main {
      opacity: .6;
    }
  }
}


