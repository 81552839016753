@import '../../helper/media';
@import '../../helper/variables';
// CHAT
.p2p__chat {
  flex-direction: column;
  align-items: flex-end;
  display: none;
  @include mediaMinWidthTablet {
    display: flex;
  }
  &.p2p__chat_second {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .chat__preview {
    display: none;
    @include mediaMaxWidthMobile {
      display: flex;
      position: fixed;
      z-index: 11;
      right: 1rem;
      bottom: 1rem;
    }
    .preview__media {
      width: 3rem;
      min-width: 3rem;
      height: 3rem;
      display: flex;
      align-items: flex-start;
      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .chat__layout {
    display: none;
    @include mediaMinWidthMobile {
      display: block;
    }
  }

  .chat__widget {
    .chat__preview {
      display: none;
      @include mediaMinWidthMobile {
        display: flex;
      }
      .preview__media {
        width: 1rem;
        min-width: 1rem;
        height: 1rem;
        display: flex;
        align-items: flex-start;
      }
    }
    .chat__control {
      .control__list {
        margin: 0 -0.75rem -1.5rem;
        width: calc(100% + 1.5rem);
        display: flex;
        @include mediaMinWidthMobile {
          display: none;
        }
      }
      .control__item {
        margin: 0 0.75rem 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
      }
      .control__media {
        width: 100%;
        height: 100%;
        img,
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.layout__chat {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mediaMaxWidthTablet {
    &.layout__chat_active {
      .chat__layout {
        display: grid;
      }
    }
  }
  @include mediaMinWidthMobile {
    padding: 0;
  }
}

// LAYOUT CHAT
.chat__preview {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;
  display: grid;
  grid-template-columns: 1rem auto;
  grid-gap: 0.25rem;
  align-items: center;
  border-radius: 2rem;
  padding: 0.5rem;
  background-color: $colorSecondary300;
  border: 1px solid $colorSecondary500;
  margin: 0 auto 0.5rem;
  @include mediaMinWidthMobile {
    margin-left: auto;
  }
  @include mediaMinWidthTablet {
    display: none;
  }
  .chat__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.chat__layout {
  border-radius: 1.5rem;
  padding: 0.75rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.03);
  background-color: $colorWhite;
  display: none;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;

  @include mediaMinWidthTablet {
    display: grid;
  }
  // CHAT HEADER
  .chat__header {
    padding: 0.75rem;
    border-radius: 1.5rem;
    display: grid;
    grid-gap: 0.25rem 0.5rem;
    grid-template-columns: 2.5rem auto;
    background-color: $colorPrimary100;
    .chat__avatar {
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 500;
      display: flex;
      grid-row: span 2;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 50%;
      background-color: $colorQuartyty500;
      .avatar__text {
        color: $colorWhite;
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 500;
      }
    }
    .chat__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.875rem;
    }
    .chat__subtitle {
      color: $colorPrimary400;
      font-size: 0.875rem;
      line-height: 1rem;
    }

    .btn__small {
      padding: 0.5rem;
      font-size: 0.75rem;
      height: 1.5rem;
    }
  }
  // CHAT MAIN
  .chat__main {
    height: 25rem;
    // overflow: hidden;
    overflow: auto;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    align-items: flex-end;
  }
  // CHAT DAY
  .chat__day {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.75rem;
    .day__header {
      text-align: center;
    }
    .day__title {
      color: $colorPrimary800;
      font-size: 0.75rem;
      line-height: 1rem;
    }
    .chat__list {
      display: flex;
      flex-direction: column;
      grid-template-columns: 1fr;
      gap: 0.75rem;
    }
  }

  // CHAT TOOLTIP
  .chat__tooltip {
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.75rem;
    border-radius: 0.75rem;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 1.125rem auto;
    margin-bottom: 0.5rem;
    &.chat__tooltip_primary {
      background-color: $colorPrimary600;
    }
    &.chat__tooltip_secondary {
      color: $colorTertiary600;
      background-color: $colorTertiary300;
    }
  }
  // CHAT MESSAGE
  .chat__message {
    display: flex;
    align-items: flex-end;
    grid-gap: 0.25rem;
    width: 100%;

    &.chat__message_primary {
      margin-right: auto;
      @include mediaMinWidthTablet {
        padding-left: 1.5rem;
      }
      .message__layout {
        background-color: #00d09e14;
      }
      .message__header {
        grid-template-columns: auto auto 1rem;
      }
      .message__name {
        color: $colorPrimary800;
      }
      .message__time {
        color: $colorPrimary800;
      }
    }
    &.chat__message_secondary {
      margin-left: auto;
      flex-direction: row;
      grid-template-columns: 1.5rem auto;
      @include mediaMinWidthTablet {
        padding-right: 1.5rem;
      }
      .message__layout {
        margin-left: auto;
        background-color: $colorPrimary100;
        border-radius: 0.75rem 0.75rem 0 0.75rem;
      }
      .message__name {
        color: $colorQuartyty500;
      }
      .message__time {
        color: $colorPrimary800;
      }
    }
    &.chat__message_tertiary {
      grid-template-columns: 1.5rem auto;
      @include mediaMinWidthTablet {
        padding-right: 1.5rem;
      }
      .message__avatar {
        background-color: $colorPrimary100;
      }
      .message__layout {
        color: $colorWhite;
        background-color: $colorQuartyty500;
      }
      .message__name {
        color: $colorPrimary100;
      }
      .message__time {
        color: $colorPrimary100;
      }
    }
    .message__avatar {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 50%;
      background-color: $colorQuartyty500;
      .avatar__text {
        color: $colorWhite;
        font-size: 0.875rem;
        line-height: 1rem;
        font-weight: 700;
      }
      img,
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
    .message__layout {
      color: $colorPrimary400;
      padding: 0.5rem;
      border-radius: 0.75rem 0.75rem 0.75rem 0;
      display: grid;
      grid-gap: 0.25rem;
      grid-template-columns: 1fr;
    }
    .message__header {
      color: $colorPrimary800;
      font-size: 0.75rem;
      line-height: 1rem;
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 0.5rem;
      align-items: center;
    }
    .message__title {
      color: $colorPrimary100;
      font-size: 0.75rem;
      line-height: 1rem;
    }

    .message__time {
      color: $colorPrimary800;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 700;
      margin-left: auto;
    }
    .message__status {
      color: $colorPrimary800;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 700;
    }

    .message__attach {
      display: grid;
      grid-template-columns: 3rem auto;
      grid-gap: 0 0.5rem;
      align-items: center;
      .attach__ico {
        grid-row: span 2;
        height: 3rem;
      }
      .attach__title {
        color: $colorPrimary400;
        font-size: 0.875rem;
        line-height: 1rem;
      }
      .attach__size {
        color: $colorPrimary800;
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }
    .message__content {
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }
  // CHAT FOOTER
  .chat__footer {
    padding: 0.125rem 0.25rem;
    flex-shrink: 0;
    border-radius: 1.5rem;
    background-color: $colorPrimary600;
    align-items: center;
    display: grid;
    grid-template-columns: 2.5rem auto 2.5rem;
    grid-gap: 0.5rem;
    .chat__attach {
      position: relative;
      &:hover {
        .attach__preview {
          background-color: $colorPrimary400;
          img,
          svg {
            stroke: $colorWhite;
          }
        }
        .attach__dropdown {
          opacity: 1;
          visibility: visible;
        }
      }
      .attach__preview {
        cursor: pointer;
        height: 2.5rem;
        background-color: $colorPrimary100;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include transition;

        img,
        svg {
          width: 1rem;
          height: 1rem;
          stroke: $colorPrimary800;
          @include transition;
        }
      }
      .attach__dropdown {
        border-radius: 1.5rem;
        padding: 0.75rem;
        background-color: $colorWhite;
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.03);
        position: absolute;
        left: 0;
        bottom: calc(100% + 1rem);
        min-width: 15rem;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        @include transition;
        transition-delay: 0.5s;
        .dropdown__list {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 0.75rem;
          .dropdown__item {
            font-size: 0.875rem;
            line-height: 1rem;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 0.5rem;
          }
          dl {
            display: flex;
            align-items: center;
            gap: 0.25rem;
          }
          dt {
            color: $colorPrimary800;
          }
          dd {
            color: $colorPrimary400;
          }
        }
      }
      .file__selected {
        background-color: $colorPrimary400;
        img,
        svg {
          stroke: $colorWhite;
        }
      }
      .delete__button {
        margin-top: 10px;
        border-top: 1px solid #000;
        padding-top: 10px;
      }
    }
    .chat__field {
      .chat__input {
        border-radius: 1.5rem;
        padding: 0.5rem 1rem;
        background-color: $colorPrimary100;
        color: $colorPrimary400;
        font-size: 1rem;
        line-height: 2.5rem;
        padding: 0.75rem;
        width: 100%;
        height: 100%;
        font-weight: 400;
        font-family: $mainFont;
        margin: 0;
        border: 0;
        text-overflow: ellipsis;
        box-sizing: border-box;
        appearance: none;
        outline: none;
        resize: none;
        max-height: 200px;
        overflow-y: auto;
      }
    }
    .chat__action {
      cursor: pointer;
      background-color: $colorPrimary100;
      border-radius: 50%;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      @include transition;
      &:hover {
        background-color: $colorPrimary400;
        img,
        svg {
          fill: $colorWhite;
        }
      }
      img,
      svg {
        width: 1rem;
        height: 1rem;
        fill: $colorPrimary800;
        @include transition;
      }
    }
    .file-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-width: 500px;
    }

    .file-name {
      flex-grow: 1;
      margin-right: 10px;
    }

    .file-size {
      margin-right: 10px;
    }

    .remove-file-btn {
      color: red;
      border: none;
      background: transparent;
      cursor: pointer;
      font-size: 20px;
    }
    .action__selected {
      background-color: $colorPrimary400;
      img,
      svg {
        fill: $colorWhite;
      }
    }
  }

  .preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .preview-media {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 0.75rem;
    }
  }
}
