.dropdown-divider {
  text-align: center;
  cursor: default;
  margin-left: auto;
  margin-right: auto;
}

.dropdown-divider .title__text {
  font-size: smaller;
  color: #666;
  text-align: center;
}
