@use 'src/media' as media;
@use 'src/variables' as variables;

.balanceBlock {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 20px;

  span {
    @include variables.fs20;

    @include media.phoneH {
      @include variables.fs16;
    }
    @include media.phoneV {
      @include variables.fs12;
    }
  }

  @include media.phoneV {
    gap: 5px;
  }
}

.subTitle {
  @include variables.fs18;

  @include media.phoneH {
    @include variables.fs14;
  }
  @include media.phoneV {
    @include variables.fs12;
  }
}

.number {
  font-weight: 500;
}

.headlines {
  @include variables.titleColor;
}

.tilda {
  margin: 0 12px;

  @include variables.titleColor;

  @include media.phone {
    margin: 0;
  }
}
