@use 'src/media' as media;
@use 'src/variables' as variables;
.paragraph {
  margin-top: 10px;
  margin-bottom: 0;
  @include variables.fs12;
  text-decoration: none;
  list-style-type: none;
  color: #666666;

  img {
    margin-right: 8px;
  }
}

.form {
  margin-top: 20px;
}

.labelWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px 10px;

  @include media.phoneV {
    grid-template-columns: repeat(2, 1fr);
  }
}
.labelWrapper:nth-of-type(2) {
  margin-top: 20px;
}
.labelWrapperAlt {
  @include media.phoneV {
    grid-template-columns: 1fr;
  }
}

.paragraphFirst {
  margin-top: 20px;
}

.paragraphOther {
  margin-top: 0;
}

.text {
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.saveButton {
  width: 210px;
  margin-top: 20px;

  text-transform: capitalize;

  @include media.phone {
    width: 170px;
  }
}