@import '../helper/media';
@import '../helper/variables';

.p2p,
.layout,
.account,
.p2p__modal {
  .formular {
    margin-bottom: -0.5rem;
    @include mediaMinWidthMobile {
      margin-bottom: -1.5rem;
    }
  }
  // Ячейка формы
  .form-items {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 0.5rem);
    margin: 0 -0.25rem;
    @include mediaMinWidthMobile {
      width: calc(100% + 1.25rem);
      margin: 0 -0.625rem;
    }
    .form-item {
      padding: 0 0.25rem;
      @include mediaMinWidthMobile {
        padding: 0 0.625rem;
      }
    }
    .form-item__ico {
      width: 1.25rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img,
      svg {
        width: 1.25rem;
        height: 0.75rem;
      }
    }
  }
  .form-item {
    margin-bottom: 0.5rem;
    @include mediaMinWidthMobile {
      margin-bottom: 1.5rem;
    }
  }

  .form-items_exhange_curency {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }

  .form-item_exhange_curency {
    flex: 0 0 auto;
    width: auto;
    margin: 0.25rem;
  }

  // Header
  .form-item__header,
  .form-item__main,
  .form-item__footer {
    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
    &.secondary-margin {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }

  .form-item__header {
    min-height: 1.25em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .form-item__title {
    color: $colorPrimary300;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 400;
    cursor: pointer;
    @include transition;
    &.form-item__title_secondary {
      font-size: 1rem;
      line-height: 2rem;
      font-weight: 500;
    }
    &:hover {
      color: $colorPrimary400;
    }
  }
  .form-item__sup {
    color: $colorTertiary500;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 500;
    transform: translateY(-0.25rem);
  }
  .form-item__subtitle {
    color: $colorPrimary400;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 400;
  }
  .form-item__main {
    &.form-item__main_row {
      margin: 0 -0.5rem;
      width: calc(100% + 0.5rem);
      display: flex;
      .form-item__field {
        margin: 0 0.25rem 0.5rem;
      }
    }
  }
  // Контент
  .form-item__content {
    color: $colorPrimary400;
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-align: center;
  }
  // Тултип
  .form-item__tooltip {
    position: relative;

    &:hover {
      .tooltip__dropdown {
        opacity: 1;
        visibility: visible;
      }
    }

    .tooltip__preview {
      display: flex;
      align-items: center;
    }

    .tooltip__title {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      .title__text {
        color: $colorPrimary300;
        font-size: 0.875rem;
        line-height: 1rem;
      }
    }

    .tooltip__media {
      width: 1rem;
      height: 1rem;
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .tooltip__dropdown {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 5;
      background-color: $colorPrimary300;
      padding: 0.5rem 1rem;
      border-radius: 1.5rem 1.5rem 0 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      @include transition;
      @include mediaMinWidthTablet {
        top: 50%;
        bottom: auto;
        left: calc(100% + 0.5rem);
        border-radius: 1.5rem;
        position: absolute;
        width: 20rem;
        @include transform-y;
      }
      p {
        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }
      }
    }

    .dropdown__main {
      font-size: 0.75rem;
      line-height: 1rem;
      padding-right: 0.5rem;
      color: $colorWhite;

      max-height: 2.5rem;

      overflow: hidden;
      overflow-y: auto;
    }
  }
  .form-item__fields {
    width: calc(100% + 1rem);
    margin: 0 -0.5rem -0.5rem;
    display: flex;
    flex-wrap: wrap;
    .form-item__field {
      padding: 0 0.75rem;
      margin-bottom: 0.5rem;
    }
  }
  .form-item__field {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
      @include mediaMinWidthMobile {
        margin-bottom: 1rem;
      }
    }
  }

  .form-item__attach {
    position: relative;
    .attach__label {
      border-radius: 1.5rem;
      background-color: $colorPrimary100;
      padding-top: 50%;
      width: 100%;
      cursor: pointer;
      display: block;
      position: relative;
    }
    .attach__input {
      position: absolute;
      left: -999999px;
    }
    .attach__ico {
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform;
    }
  }

  // Свитчер
  .form-item__switch {
    &.form-item__switch_up {
      .switch__input {
        &:checked {
          & ~ .switch__layout {
            color: $colorWhite;
            background-color: $colorSecondary500;
          }
        }
      }
    }
    &.form-item__switch_down {
      .switch__input {
        &:checked {
          & ~ .switch__layout {
            color: $colorWhite;
            background-color: $colorTertiary600;
          }
        }
      }
    }

    &.form-item__disabled {
      pointer-events: none;
    }
    .switch__label {
      position: relative;
      cursor: pointer;
    }
    .switch__input {
      position: absolute;
      left: -999999px;
      &:checked {
        & ~ .switch__media {
          order: 2;
        }
      }
    }
    .switch__layout {
      border-radius: 1.5rem;
      padding: 0.25rem;
      color: $colorPrimary400;
      background-color: $colorPrimary600;
      display: flex;
      align-items: center;
    }
    .switch__media {
      background-color: $colorWhite;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      img,
      svg {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
    .switch__title {
      min-width: 9.25rem;
      width: 100%;
      text-align: center;
      .switch__text {
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 500;
      }
    }
  }

  // Поле ввода
  .form-item__scope {
    color: $colorPrimary400;
    background-color: $colorWhite;
    border-radius: 2rem;
    height: 2.5rem;
    border: 0.0625rem solid $colorPrimary400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    @include transition;
    .scope__before,
    .scope__after {
      color: $colorPrimary300;
      font-size: 1rem;
      line-height: 2.5rem;
      font-weight: 500;
      padding: 0.75rem;
      white-space: nowrap;
      display: flex;
      align-items: center;
      &:first-child {
        padding-right: 0;
      }

      &:last-child {
        padding-left: 0;
      }
      span {
        &:not(:last-child) {
          margin-right: 0.25rem;
        }
      }
      img,
      svg {
        width: 1rem;
        min-width: 1rem;
        height: 1rem;
      }
    }

    .scope__input {
      color: $colorPrimary400;
      background-color: transparent;
      font-size: 1rem;
      line-height: 2.5rem;
      padding: 0 0.75rem;
      min-height: 2.5rem;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-family: $mainFont;
      margin: 0;
      border: 0;
      text-overflow: ellipsis;
      box-sizing: border-box;
      appearance: none;
      outline: none;
    }
    &.scope_disabled {
      background-color: $colorPrimary600;
      border-color: $colorPrimary600;
      pointer-events: none;
    }
    // подсказка
    &::placeholder {
      opacity: 1;
      color: $colorPrimary300;
    }

    // активное поле
    //&:focus,
    //&:hover {
    //  border-color: $colorPrimary400;
    //}
    // екстареа
    &.form-item__input_textarea {
      width: 100%;
      height: 6.875rem;
      resize: none;
    }
  }

  // Данные
  .form-item__data {
    color: $colorPrimary300;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: calc(100% + 1.5rem);
    margin: 0 -0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
    .data__item {
      padding: 0 0.75rem;
      margin: 0;
      &.data__item_after {
        margin-left: auto;
        white-space: nowrap;
      }
    }
    .btn {
      &:hover {
        color: $colorWhite;
        background-color: $colorPrimary400;
      }
    }
  }

  // Текстареа
  .textarea {
    position: relative;
    textarea {
      color: $colorPrimary400;
      font-size: 1rem;
      line-height: 1rem;
      padding: 1.25rem 0.75rem 0.75rem;
      font-weight: 500;
      font-family: $mainFont;
      margin: 0;
      background-color: $colorWhite;
      border-radius: 1.25rem;
      height: 6.25rem;
      border: 0.0625rem solid $colorPrimary400;
      resize: none;
      box-sizing: border-box;
      appearance: none;
      outline: none;
    }
    .textarea__progress {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      color: $colorPrimary300;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 500;
      @include mediaMinWidthMobile {
        font-size: 0.875rem;
        line-height: 1rem;
        font-weight: 400;
      }
    }
  }

  // Чекбокс
  .checkbox {
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    .checkbox__label {
      position: relative;
      display: flex;
    }

    .checkbox__input {
      position: absolute;
      opacity: 0;
      // нажатое состояние
      &:checked ~ .checkbox__media {
        .checkbox__ico {
          &.checkbox__ico_before {
            opacity: 0;
          }

          &.checkbox__ico_after {
            opacity: 1;
          }
        }
      }

      &:disabled ~ .checkbox__text {
        color: $colorPrimary300;
        pointer-events: none;
      }
      &.error ~ .checkbox__text {
        color: $colorTertiary500;
      }
    }

    .checkbox__main {
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 500;
      padding-left: 1.5rem;
      position: relative;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
    }
    .checkbox__text {
      color: $colorPrimary400;
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
      a {
        color: $colorQuartyty500;
      }
    }
    .checkbox__prompt {
      color: $colorPrimary300;
    }
    .checkbox__label {
      position: unset;
    }
    .checkbox__media {
      position: relative;
    }
    .checkbox__ico {
      width: 1rem;
      min-width: 1rem;
      height: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      @include transition;
      &.checkbox__ico_before {
        opacity: 1;
      }
      &.checkbox__ico_after {
        opacity: 0;
      }
    }
  }
}

// Календарь
.p-calendar {
  color: $colorPrimary400;
  background-color: $colorWhite;
  border-radius: 2rem;
  height: 2.5rem;
  display: block;
  &.p-calendar_secondary {
    color: $colorPrimary400;
    background-color: $colorPrimary200;
  }
  .p-inputtext {
    border: 0;
    height: 2.5rem;
    padding: 0.75rem 0.75rem 0.75rem 2.75rem;
    width: 100%;
    outline: none;
    color: $colorPrimary400;
    background-color: transparent;
    font-size: 1rem;
    line-height: 2.5rem;
    font-weight: 500;
    font-family: $mainFont;
    margin: 0;
    text-overflow: ellipsis;
    box-sizing: border-box;
    appearance: none;
    background: no-repeat url('../../../public/images/calendar.svg');
    background-position: 0.5rem 50%;
    background-size: 1.25rem;
  }
}
.p-datepicker {
  background: $colorWhite;
  padding: 0.5rem;
  border-radius: 1rem;
  width: 18.5rem;
  .p-datepicker-title {
    display: flex;
    flex-direction: row-reverse;
    .p-link {
      color: $colorPrimary500;
      font-size: 1.25rem;
      line-height: 1.25rem;
      &.p-datepicker-month {
        margin-right: 0.25rem;
      }
    }
  }
  .p-datepicker-prev,
  .p-datepicker-next {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: $colorPrimary200;
    display: flex;
    justify-content: center;
    align-items: center;
    path {
      fill: $colorPrimary500;
    }
  }
  tr {
    display: flex;
  }
  th,
  td {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    color: $colorPrimary500;
    background-color: $colorWhite;
    display: flex;
    justify-content: center;
    align-items: center;
    @include transition;
  }
  td {
    &.p-datepicker-today {
      background-color: $colorQuartyty300;
    }
    &:hover {
      background-color: $colorPrimary200;
    }
  }
  .p-highlight {
    color: $colorQuartyty500;
  }
}
.form-item__icocheck {
  .icocheck__label {
    position: relative;
    cursor: pointer;
  }
  .icocheck__input {
    position: absolute;
    left: -99999px;
    &:checked + .icocheck__layout {
      color: $colorWhite;
      background-color: $colorQuartyty500;
    }
  }
  .icocheck__layout {
    padding: 0.5rem;
    border-radius: 2rem;
    color: $colorPrimary500;
    background-color: $colorPrimary200;
    display: flex;
    align-items: center;
    @include transition;
  }
  .icocheck__media {
    width: 1.5em;
    height: 1.5rem;
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
    img,
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .icocheck__title {
    .icocheck__text {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
    }
  }
}

// Селекты
.p-dropdown,
.p-multiselect {
  background-color: $colorPrimary600;
  border-radius: 2rem;
  border: 0.0625rem solid $colorPrimary600;
  height: 2.5rem;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  @include transition;

  &.p-invalid {
    background-color: $colorWhite;
    border-color: $colorTertiary500;

    .p-dropdown-label.p-placeholder {
      color: $colorTertiary500;
    }

    .p-dropdown-trigger {
      img,
      svg {
        path {
          fill: $colorTertiary500;
        }
      }
    }
  }

  &.p-disabled {
    border-color: $colorWhite;
    background-color: $colorWhite;
    pointer-events: none;
    cursor: unset;
    .p-dropdown-trigger {
      opacity: 0;
      visibility: hidden;
    }
  }
  .p-hidden-accessible {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 0;
    left: -99999px;
  }
  .p-dropdown-label {
    color: $colorPrimary400;
    font-size: 1rem;
    line-height: 2.5rem;
    font-weight: 500;
    font-family: $mainFont;
    display: block;
    overflow: hidden;

    &.p-placeholder {
      color: $colorPrimary300;
    }
  }
  .p-dropdown-tags {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
  .p-dropdown-trigger {
    width: 0.5625rem;
    height: 0.375rem;
    margin-left: auto;
    background: no-repeat center/contain url('../../../public/images/arrowBottom.svg');
  }
}
.p-dropdown-template {
  display: flex;
  align-items: center;
  &.p-dropdown-template_active {
    .p-dropdown-title {
      .title__text {
        color: $colorQuartyty500;
      }
    }
  }
}
.p-dropdown-media {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-right: 0.75rem;
  }

  img,
  svg {
    width: 100%;
    height: 100%;
  }
}
.p-dropdown-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  .title__text {
    color: $colorPrimary400;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
}
.p-dropdown-panel,
.p-multiselect-panel {
  background-color: $colorPrimary200;
  border-radius: 1.25rem;
  border: 0.0625rem solid $colorPrimary200;
  transform: translateY(0.25rem);
  overflow: hidden;
  position: absolute;

  .p-dropdown-header,
  .p-multiselect-header {
    padding: 0.5rem;

    .p-dropdown-filter-container,
    .p-multiselect-filter-container {
      color: $colorPrimary400;
      border-radius: 2rem;
      border: 0.0625rem solid $colorPrimary400;
      display: flex;
      align-items: center;
      position: relative;
    }

    .p-dropdown-filter,
    .p-multiselect-filter {
      color: $colorPrimary400;
      background-color: transparent;
      font-size: 1rem;
      line-height: 2.5rem;
      height: 2.5rem;
      padding: 0.75rem 0.75rem 0.75rem 2.5rem;
      font-weight: 500;
      font-family: $mainFont;
      margin: 0;
      border: 0;
      text-overflow: ellipsis;
      box-sizing: border-box;
      appearance: none;
      outline: none;
    }

    .p-dropdown-filter-icon,
    .p-multiselect-filter-icon {
      position: absolute;
      top: 0.65rem;
      left: 0.65rem;
      width: 1.25rem;
      height: 1.25rem;
      //background: no-repeat center/contain url('../../../public/images/searchIco.svg');
      //background-size: 50%;
      margin: 0;
      g {
        display: none;
      }
    }

    .p-dropdown-close,
    .p-multiselect-close {
      display: none;
    }
  }

  .p-dropdown-items,
  .p-multiselect-items {
    display: flex;
    flex-direction: column;
    .p-dropdown-items-wrapper {
      overflow: hidden;
      overflow-y: auto;
    }
  }

  .p-dropdown-item,
  .p-multiselect-item {
    padding: 0.5rem;
    margin: 0;
    background-color: $colorPrimary200;
    display: flex;
    align-items: center;
    cursor: pointer;
    @include transition;

    &:hover,
    &.p-highlight {
      background-color: $colorPrimary100;
    }

    .p-checkbox {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      .p-checkbox-box {
        color: $colorQuartyty500;
        border: 0.125rem solid $colorQuartyty500;
        width: 1rem;
        height: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        img,
        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  .p-dropdown-empty-message {
    color: $colorTertiary500;
    padding: 0.75rem;
  }
}
