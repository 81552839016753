@import '../../helper/media';
@import '../../helper/variables';
// WYSIWYG
.wysiwyg {
  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
  &.wysiwyg_secondary-margin {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  img,
  svg {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
  ul,
  p,
  ol {
    padding: 0;
    margin-bottom: 0;
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
    &:empty {
      display: none;
    }
    li {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
  ul {
    li {
      color: $colorPrimary400;
      position: relative;
      padding-left: 1.5rem;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:before {
        content: '';
        left: 0;
        top: 0.4rem;
        width: 1rem;
        height: 0.625rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('../../../../public/images/check.svg');
        position: absolute;
      }
    }
  }

  ol {
    counter-reset: my-awesome-counter;
    list-style: none;
    li {
      counter-increment: my-awesome-counter;
      padding-left: 2rem;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:before {
        color: $colorWhite;
        background-color: $colorPrimary400;
        font-size: 0.875rem;
        line-height: 1rem;
        content: counter(my-awesome-counter);
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -0.25rem;
        left: 0;
      }
    }
  }

  i {
    font-style: italic;
  }
  small {
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
}
.transparentHalf {
  opacity: 0.5;
}
