@use 'src/media' as media;
@use 'src/variables' as variables;

.tabWrapper {
  width: 660px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);

  button {
    width: 100%;
  }

  @include media.phoneH {
    width: 510px;
  }
  @include media.phoneV {
    width: 100%;
    grid-template-columns: repeat(3, auto);
    gap: 5px;
  }
}
.optionsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include media.phoneV {
    display: grid;
    gap: 10px;
  }
}

.fieldsWrapper {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.fieldWrapper {
  width: 166px;
  display: grid;
  gap: 5px;

  @include media.phoneH {
    width: 140px;
  }
  @include media.phoneV {
    width: 150px;
  }
}
.fieldTitle {
  @include variables.fs14;
  font-weight: 500;
}
.dateInputsWrapper{
  display: flex;
  margin: 0 -4px;
  width: calc(100% + 8px);
}
.dateInputWrapper{
  margin: 0 4px;
}
.fieldWrapperDate {
  width: 312px;
  @include media.phoneH {
    width: 240px;
  }
  @include media.phoneV {
    width: 215px;
  }
}
.dateInput {
  padding-left: 45px;
  @include media.phone {
    padding-left: 34px;
  }
}
.calendar {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);

  @include media.phone {
    width: 15px;
    height: 14px;
  }
}

.optionButtonsWrapper {
  margin-bottom: 3px;
  display: flex;
  gap: 20px;

  @include media.phoneH {
    display: grid;
    justify-content: flex-end;
    gap: 10px;
  }
}
.optionButton {
  height: 22px;
  text-align: right;

  span {
    @include variables.fs18;
    font-weight: 400;
    white-space: nowrap;

    @include variables.titleColorAlt;
  }
  span:before {
    content: '';
    position: absolute;
    top: calc(100% + 3px);
    width: 100%;
    left: 0;
    height: 1px;

    background: variables.$clickColorForButtons;
  }

  @include media.mobileDevices {
    height: 17px;
    span {
      @include variables.fs14;
    }
    span:before {
      top: calc(100% + 2px);
    }
  }
}

.table {
  position: relative;
  width: calc(100% + 19px * 2);
  margin: -19px;
  margin-top: 16px;
  margin-bottom: -10px;
  display: grid;
  background: variables.$bgWhite;
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  overflow: hidden;
  @include media.mobileDevices {
    margin-bottom: -19px;
  }
  @include media.phoneV {
    margin: -9px;
    margin-top: 5px;
    width: calc(100% + 9px * 2);
  }
}
.desktopOnly {
  @include media.mobileDevices {
    display: none;
  }
}
.mobileOnly {
  display: none;
  @include media.mobileDevices {
    display: grid;
  }
}
.row {
  width: 100%;
  height: fit-content;
  padding: 1px 19px;
  display: grid;
  justify-content: space-between;
  align-items: center;
}
.rowHistory {
  grid-template-columns: 14% 9% 8% 11% 10% 43%;
}
.rowHistoryCash {
  grid-template-columns: 14% 18% 5% 7% 10% 11% 17%;
}
.rowTurnover {
  grid-template-columns: 9% 12% 13% 13% 21% 9% 9%;
}
.rowMobile {
  justify-content: flex-start;
  gap: 20px;
  grid-template-columns: 40% auto;
}
.rowMobileTitleWrapper {
  display: flex;
  gap: 20px;
}
.imgRotate {
  transform: rotate(-90deg);
}
.grey {
  color: variables.$grey;
}
.titleRow {
  height: fit-content;
  padding-top: 8px;
  padding-bottom: 8px;

  @include media.mobileDevices {
    justify-content: space-between;
    grid-template-columns: auto auto;
    @include variables.fs16;
  }
}
.cell {
  width: 100%;
  height: fit-content;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: variables.$fontBlack;
  // white-space: nowrap;

  font-weight: 400;
  @include variables.fs14;
}
.cellColoredBg {
  background: linear-gradient(135deg, #f9f2ff 0%, #faf2fc 100%);
}

.tableTitle {
  font-weight: 500;
  @include variables.fs14;
  @include media.mobileDevices {
    @include variables.fs16;
  }
}
.hiddenWrapper {
  height: 0;
  overflow: hidden;
}
.TxID {
  @include variables.titleColor;
}
.cancelled {
  color: variables.$fontRed;
}
.credited {
  color: variables.$fontGreen;
}
.cancelButton {
  height: 15px;
  @include variables.fs14;
  color: variables.$grey;
  @include media.mobileDevices {
    height: 13px;
    @include variables.fs12;
  }
}
.line {
  height: 3px;
  margin: 0 20px;
  background: variables.$clickColorForButtons;
}
.lineMobile {
  height: 1px;
  background: variables.$blockBorderColor;
}
.paginationWrapper {
  width: 420px;
  height: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @include media.phoneH {
    width: 350px;
  }
  @include media.phoneV {
    width: 330px;
  }
}
.paginationCenterWrapper {
  height: fit-content;
  display: flex;
  place-content: center;
  gap: 10px;

  @include media.phone {
    gap: 8px;
  }
}
.arrowButton {
  aspect-ratio: 1;
  display: grid;
  place-content: center;

  img {
    filter: invert(14%) sepia(65%) saturate(7464%) hue-rotate(274deg) brightness(92%) contrast(127%);
    @include media.phone {
      width: 8.75px;
      height: 12.25px;
    }

    @include media.phoneV {
      width: 7.5px;
      height: 10.5px;
    }
  }
}
.arrowRight {
  transform: rotate(180deg);
  @media (hover: hover) {
    &:not(:disabled):hover {
      box-shadow: none;
      img {
        filter: invert(100%) sepia(5%) saturate(7479%) hue-rotate(279deg) brightness(116%)
          contrast(105%);
      }
    }
  }
  &:not(:disabled):active {
    box-shadow: none;
    img {
      filter: invert(100%) sepia(5%) saturate(7479%) hue-rotate(279deg) brightness(116%)
        contrast(105%);
    }
  }
}
.pageNumber {
  width: 30px;
  @include variables.fs16;
  place-self: center;
  text-align: center;

  cursor: pointer;

  @include media.phone {
    width: 26px;
    @include variables.fs14;
  }
}
.pageNumberActive {
  @include variables.titleColor;
}
.ellipsisWrapper {
  width: 40px;
  @include media.phone {
    width: 35px;
  }

  @include media.phoneV {
    width: 30px;
  }
}
.ellipsis {
  padding: 0;
  text-align: center;
  @include variables.fs16;
  border: 2px solid variables.$mainColorForButtonBorder;
}
.notification1Figure {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(8px) translateY(-8px);
  display: grid;
  place-items: center;
  place-content: center;

  background-color: variables.$blockBorderColor;
  color: variables.$bgWhite;
  border-radius: 10px;
  @include variables.fs14;

  @include media.phoneV {
    width: 16px;
    height: 16px;
    transform: translateX(4px) translateY(-6px);
    @include variables.fs12;
  }
}
.notificationMoreThan1Figure {
  width: fit-content;
  padding: 0 4px;
}
